<template>
    <v-card>
        <v-card-text>
            <v-row>
        <v-datetime-picker label="Fecha Inicio" v-model="datetime">
             <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
            </template>
            <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
            </template>
        </v-datetime-picker>       
            <v-slider
                v-model="hours"
                class="align-center px-10"
                :max="24"
                :min="1"
                label="Horas"
                hide-details
            >
                <template v-slot:append>
                <v-text-field
                    v-model="hours"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    disabled
                ></v-text-field>
                </template>
            </v-slider>
            <v-btn color="blue darken-1" text outlined @click='search'>
              Buscar
            </v-btn>
            </v-row>
        </v-card-text>
        <v-card-text>
            <v-select
            v-model="selection"
            :items="items"
            attach
            chips
            label="Dispositivos"
            
            item-text="name"
            item-value="deviceId"
            return-object
          ></v-select>
        </v-card-text>
    </v-card>
</template>

<script>
var qs = require('query-string');
import { mapActions } from 'vuex';

export default{
    data:()=>({
        datetime: new Date(),
        hours: 1,
        items:[{ deviceId:1,name:'uno'},{ deviceId:2,name:'dos'}],
        selection:[],
    }),
    mounted(){
        this.loadDevices();
    },
    methods:{
        ...mapActions(['getHistory','getDevices','showSnackbar']),
        loadDevices(){
            this.getDevices('?'+qs.stringify({pageSize:30})).then((res)=>{
                this.items = res.data.data.filter(it =>  it.active);
                //console.log(res);
            }).catch((err)=>{
                console.log(err);
            });
        },
        search(){
            //console.log(this.selection);
            if(this.selection.length < 1){
                this.showSnackbar({
                    showing:true,
                    message: `Favor de seleccionar al menos un dispositivo`,
                    color:'error'
                });
                return;
            }

            var searchData ={
                startDate: this.datetime.toDateString() + ' ' + this.datetime.toLocaleTimeString() ,
                hours: this.hours,
                devIds: [this.selection.id]
            }
//            console.log(qs.stringify(searchData));
            this.getHistory('?'+qs.stringify(searchData))
            .then((res)=>{
                //this.items = res.data.data;
                this.$emit('onSearch',res.data);
                //console.log(res);
            }).catch((err)=>{
                console.log(err);
            });
        }
    }
}
</script>
